<template>
  <div class="container">
    <div class="query-title">
      <div class="demo-input-suffix">
        用户名称：
        <el-input placeholder="用户名称ID" class="input-tit" v-model="name">
        </el-input>
      </div>
      <div class="demo-input-suffix">
        用户手机号：
        <el-input placeholder="用户手机号" class="input-tit" v-model="phone">
        </el-input>
      </div>
      <div class="demo-input-suffix">
        订单号：
        <el-input placeholder="订单号" class="input-tit" v-model="outTradeNo">
        </el-input>
      </div>
      <div class="demo-input-suffix">
        <el-button type="primary" class="tit-button" @click="querys()">查 询</el-button>
        <el-button type="primary" class="tit-button" @click="reset()">重 置</el-button>
      </div>
      <div class="demo-input-suffix">
        <el-radio-group v-model="state" @change="typeChange">
          <el-radio v-for="(item,i) in statelist" :key="i" :label="item.id" style="margin:7px 10px 0px 0px;">
            {{item.name}}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="demo-input-suffix" style="width: 100%;">
        <p class="titname">委托找活列表</p>
        <!-- <el-button type="primary" class="tit-button" @click="addBtn">新 增</el-button> -->
      </div>
    </div>
    <div class="table-container">
      <el-table highlight-current-row :header-cell-style="{ background: 'rgba(113,183,255,0.4)', color: '#606266', }"
        :data="tabledata" :height="tableHeight" border style="width: 100%">
        <el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
        <el-table-column prop="outTradeNo" label="订单号" align="center"></el-table-column>
        <el-table-column prop="createTime" label="交易时间" align="center"></el-table-column>
        <el-table-column prop="packageName" label="服务套餐" align="center"></el-table-column>
        <el-table-column prop="packageMoney" label="支付金额" align="center">
          <template slot-scope="scope">
            <p>{{scope.row.packageMoney/100}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="联系人" align="center"></el-table-column>
        <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="state" label="当前状态" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.state==0">审核中</p>
            <p v-if="scope.row.state==1">已拒绝</p>
            <p v-if="scope.row.state==2">服务中</p>
            <p v-if="scope.row.state==3">已退款</p>
            <p v-if="scope.row.state==4">已完成</p>
            <p v-if="scope.row.state==5">退款中</p>
          </template>
        </el-table-column>
        <el-table-column prop="refundMsg" label="退款原因" align="center"></el-table-column>
        
        
        <el-table-column prop="" label="操作" width="170" align="center">
          <template slot-scope="scope">

            <el-button class="tabBnt" v-if="scope.row.state==0" type="text" @click="pass(scope.row, scope.$index)">审核通过
            </el-button>
            <el-button class="tabBnt" v-if="scope.row.state==0" type="text" @click="refuse(scope.row, scope.$index)">
              拒绝通过</el-button>
            <!-- <el-button class="tabBnt" v-if="scope.row.distribution==0" type="text" @click="refuse(scope.row, scope.$index)">退款通过</el-button>
            <el-button class="tabBnt" v-if="scope.row.distribution==0" type="text" @click="refuse(scope.row, scope.$index)">退款拒绝</el-button>
           -->

            <el-button class="tabBnt" v-if="scope.row.state==2" type="text"
              @click="Completetheservice(scope.row, scope.$index)">完成服务</el-button>
            <el-button class="tabBnt" v-if="scope.row.state==2" type="text"
              @click="termination(scope.row, scope.$index)">服务失败</el-button>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fye">
      <pagintion :ToTal="total" :pageNums="pagenums" @dsydata="DsyData" @dstdata="DstData" />
    </div>
  </div>
</template>
<script>
  import pagintion from "@/components/pagintion/pagintion.vue";
  import {
    gentrustadminList,
    gentrustadopt,
    gentrustrefuse,
    gentrustcomplete,
    gentrustfail

  } from "@/api/api";
  export default {
    name: "memberregistration",
    components: {
      pagintion,
    },
    props: {},
    data() {
      return {
        name: '',
        phone: '',
        outTradeNo: '',
        state: 5,
        statelist: [{
            name: '全部',
            id: 5
          },
          {
            name: '审核中',
            id: 0
          },
          {
            name: '已拒绝',
            id: 1
          },
          {
            name: '服务中',
            id: 2
          },
          {
            name: '已退款',
            id: 3
          },
          {
            name: '已完成',
            id: 4
          },
        ],
        tabledata: [],
        tableHeight: window.innerHeight - 280,
        dialogData: [],
        pageNum: 1,
        pageSize: 20,
        total: null,
        pagenums: null,
        dialogVisible: false,
        title: "",
      };
    },
    computed: {},
    watch: {},
    created() {
      window.addEventListener('resize', this.getHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.getHeight)
    },
    beforeDestroy() {},
    mounted() {

      this.Datalist();
    },
    methods: {
      querys() {
        this.pageNum = 1
        this.pageSize = 20
        this.pagenums = 1;
        this.Datalist();
        this.state = 5;
      },
      reset() {
        this.name = ''
        this.phone = ''
        this.outTradeNo = '';
        this.state = 5;
        this.pageNum = 1
        this.pageSize = 20
        this.pagenums = 1;
        this.Datalist()
      },
      typeChange(val) {

        this.Datalist()

      },
      getHeight() {
        this.tableHeight = window.innerHeight - 280;
      },



      DsyData(data) {
        this.pagenums = data.dsy;
        this.pageNum = data.dsy;
        this.Datalist()
      },
      DstData(data) {
        this.pageSize = data.dst;
        this.Datalist()
      },
      termination(row, index) {
        this.$confirm("此操作将终止该条数据服务, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm"){
            let params = {

              id: row.id,

            }
            gentrustfail(params).then((res) => {

              if (res.status == 200) {

                this.Datalist();

                this.$message({
                  message: "已拒绝",
                  type: "success",
                });

              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("修改失败！");
            });
          }
        }).catch(() => {});


      },
      refuse(row, index) {
        this.$confirm("此操作将拒绝该申请, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            gentrustrefuse(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "已拒绝",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("修改失败！");
            });
          }
        }).catch(() => {});

      },
      pass(row, index) {
        this.$confirm("此操作将该通过申请, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            gentrustadopt(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "已通过",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("修改失败！");
            });
          }
        }).catch(() => {});
      },
      Completetheservice(row) {
        this.$confirm("此操作将完成该数据服务, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            gentrustcomplete(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "已通过",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("修改失败！");
            });
          }
        }).catch(() => {});
      },


      deletes(row) {
        this.$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = [row.id]
            eworkgroupdelete(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("删除失败！");
            });
          }
        }).catch(() => {});
      },
      Datalist() {
        let params = {
          name: this.name,
          phone: this.phone,
          outTradeNo: this.outTradeNo,
          state: this.state,
          size: this.pageSize,
          current: this.pageNum,
        }
        gentrustadminList(params).then((res) => {
          console.log(res)
          this.tabledata = res.data.list;
          //this.tabledata = res.data;
          this.total = Number(res.data.total);
        }).catch((error) => {
          this.$message.error("获取数据失败！");
        });
      },
    },
  };
</script>

<style scoped>

</style>
