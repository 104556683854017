<template>
  <div class="pagination">
    <div class="pagcontent">
      <div class="block">
        <el-pagination
          :page-sizes="[20, 50, 100, 200]"
          :page-size="1"
          :total="total"
          layout="total,sizes, prev, pager, next, jumper"
          :current-page="pageNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    ToTal: Number,
    pageNums: Number,
  },
  data() {
    return {
      total: null, // 总页数
      pageNum: 1, //
      Howmanybarsdata: "",
      Currentpage: "",
    };
  },
  computed: {},
  watch: {
    ToTal(ToTal) {
      this.total = this.ToTal;
    },
    pageNums(pageNums) {
      this.pageNum = pageNums;
    },
  },
  created() {},
  mounted() {
    this.pageNum = this.pageNums;
  },
  methods: {
    handleSizeChange(val) {
      // 显示每页多少条数据
      this.Howmanybarsdata = val;
      this.$emit("dstdata", { dst: this.Howmanybarsdata });
    },
    handleCurrentChange(val) {
      // 当前页
      this.pageNum = val;
      this.Currentpage = val;
      this.$emit("dsydata", { dsy: this.Currentpage });
    },
  },
};
</script>

<style scoped >
/*.pagination{
		position: absolute;
		left: 0;
		top: 0px;
		right: 0px;
		bottom: 0px;
		margin: auto;
	}*/

>>> .el-pager {
  height: 20px;
}
>>> .el-pager li {
  height: 20px;
  line-height: 26px;
}
</style>
